/* eslint-disable react-hooks/exhaustive-deps */
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import { Check, Eye, PencilSquare, PlusSquare, Trash3, ArrowClockwise } from 'react-bootstrap-icons';
import Spinner from 'react-bootstrap/Spinner';

import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import DatasourceEditCSV from "./edit_csv";

function DatasourceList() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [activeDatasource, setActiveDatasource] = useState("");
    const [datasourceId, setDatasourceId] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [datasources, setDatasources] = useState([]);

    const [showEditCSV, setShowEditCSV] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRestore, setShowRestore] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [toRestore, setToRestore] = useState("");

    const [updatingDS, setUpdatingDS] = useState([]);

    const { t } = useTranslation();

    let stream = useRef();
    stream.current = null;

    function handleNewButton(id) {
        setActiveDatasource("new");
        setDatasourceId("")
        setShowEditCSV(true);
    }

    function refresh() {
        loadListData().then(() => getUpdatingDatasources());
    }

    function loadListData() {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasources";

        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setDatasources(res.data.datasources);
            }
            setIsLoading(false);
        });
    }

    function handlePreviewButton(id) {
        navigate("/datasources/preview/" + id);
    }

    function removeFromUpdating(id) {
        setUpdatingDS(updatingDS.filter((item) => item !== id));
    }

    function getUpdatingDatasources() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasources/updating";
        axios.get(ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res.data && res.data.datasources) {
                setUpdatingDS(res.data.datasources.map((item) => item._id))
            }
        });
    }

    function handleUpdateButton(id) {
        if (updatingDS.includes(id)) return;
        let newArray = [...updatingDS, id];
        setUpdatingDS(newArray);

        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource/update";
        axios.post(ENDPOINT,
            { id: id },
            {
                withCredentials: true,
            }).then((res) => {
                removeFromUpdating(id);
                loadListData();
            });
    }

    function handleEditButton(id) {
        setDatasourceId(id)
        setShowEditCSV(true);
    }

    function handleDeleteButton(id) {
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData()
            if (activeDatasource === toDelete) {
                setActiveDatasource("");
            }
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function handleRestoreButton(id) {
        setToRestore(id);
        setShowRestore(true);
    }
    function handleRestoreOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/datasource";
        axios.patch(ENDPOINT,
            { id: toRestore },
            { withCredentials: true, }
        ).then((res) => {
            setToRestore("");
            setShowRestore(false)
            loadListData();
        });
    }
    function handleRestoreCancel() {
        setToRestore("");
        setShowRestore(false);
    }

    function subscribeToSocket() {
        if (stream.current !== null) {
            stream.current.removeEventListener("ds-updated", refresh);
            stream.current.close();
            stream.current = null;
        }

        const roomID = userId;
        const SOCKET_ENDPOINT = process.env.REACT_APP_API_URL.replace("http", "ws") + "stream/" + roomID;

        stream.current = new WebSocket(SOCKET_ENDPOINT);
        stream.current.addEventListener("ds-updated", refresh);
        window.addEventListener("unload", function () {
            if (stream.current.readyState === WebSocket.OPEN) {
                stream.current.close();
                stream.current = null;
            }
        });

    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const canManageDatasources = res.data.user.role.access.manage_all_datasources || res.data.user.role.access.manage_self_datasources || true;
                        if (!canManageDatasources) {
                            navigate("/");
                        } else {
                            loadListData();
                            getUpdatingDatasources();
                        }
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                        // }).finally(() => subscribeToSocket());
                    })
            } else {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <div className='d-flex justify-content-between my-2'>
                <h4 className='madevo-text-secondary'>{t("Datasources")}</h4>
                <div className=''>
                    {isLoading &&
                        <Spinner animation="border" role="status" size='sm' variant='secondary' className='mx-2'>
                            <span className="visually-hidden">{t("Loading")}...</span>
                        </Spinner>
                    }
                    {!isLoading &&
                        <Button variant='outline-secondary' className='btn-sm mx-2' onClick={refresh}>
                            <ArrowClockwise style={{fontSize: "12pt"}} />
                        </Button>
                    }
                    <Button variant='outline-secondary'
                            className='btn-sm ml-2 madevo-btn-green justify-content-center new-btn'
                            onClick={handleNewButton}
                        >
                        <PlusSquare className='new-btn-plus' /> <span>{t("New Datasource")}</span>
                    </Button>
                </div>
            </div>
            {datasources.length > 0 &&
                datasources.map((item) => {
                    if (item.files === undefined) {
                        item.files = [item.filename];
                    }
                    return (
                        <ListGroup className='my-2' key={item._id}>
                            <ListGroup.Item>
                                <div className='d-flex justify-content-start'>
                                    <h5 style={{marginBottom: "0px", marginTop: "2px"}}>{item.name}</h5>
                                </div>
                                <div className="d-flex w-100 justify-content-between">
                                    <small className="text-body-secondary">
                                        {
                                            item.files.map((f) => {
                                                return <Badge bg="" className="mx-1 madevo-badge" key={f}>
                                                    {f}
                                                </Badge>
                                            })
                                        }
                                    </small>
                                    <div style={{ marginTop: "-10px" }}>
                                        {t("Updated At")}: {moment(item.updated_at).format('MMMM Do YYYY, H:mm')}
                                    </div>
                                    <small style={{ marginTop: "-13px" }}>
                                        <ButtonGroup aria-label="Actions" size='sm'>
                                            {updatingDS.includes(item._id) &&
                                                <>
                                                    <Eye role='button' className='list-action disabled' title={t('View Data Source')} />
                                                    <Spinner animation="border" role="status" size='sm' className='list-action disabled me-3 my-1'>
                                                        <span className="visually-hidden">{t("Updating...")}...</span>
                                                    </Spinner>
                                                    <PencilSquare role='button' className='list-action disabled' title={t('Edit Data Source')} />
                                                    <Trash3 role='button' className='list-action red-action disabled' title={t('Delete Data Source')} />
                                                </>
                                            }
                                            {!updatingDS.includes(item._id) &&
                                                <>
                                                    <Eye role='button' className='list-action' onClick={() => handlePreviewButton(item._id)} title={t('View Data Source')} />
                                                    <ArrowClockwise role='button' className='list-action' onClick={() => handleUpdateButton(item._id)} title={t('Re-Import Data')} />
                                                    <PencilSquare role='button' className='list-action' onClick={() => handleEditButton(item._id)}title={t('Edit Data Source')} />
                                                    <Trash3 role='button' className='list-action red-action' onClick={() => handleDeleteButton(item._id)}title={t('Delete Data Source')} />
                                                </>
                                            }
                                        </ButtonGroup>
                                    </small>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                }
                )
            }
            {!isLoading && datasources.length <= 0 &&
                <ListGroup>
                    <ListGroup.Item>
                        <h5 className='text-center text-secondary'>{t("No Data")}</h5>
                    </ListGroup.Item>
                </ListGroup>
            }
            {showEditCSV &&
                <DatasourceEditCSV show={showEditCSV} handleShow={setShowEditCSV} user_id={userId} datasource_id={datasourceId} setDatasourceId={setDatasourceId} reLoad={loadListData} datasources={datasources} />
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.datasource")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
            {
                showRestore &&
                <>
                    <Modal
                        show={showRestore}
                        onHide={() => setShowRestore(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you Sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.restore.datasource")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleRestoreCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleRestoreOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}

export default DatasourceList;

