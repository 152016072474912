import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import AuthContext from "../helpers/AuthContext";
import React, { useEffect, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import Navbar from "./navbar";
import Footer from "./footer";

import axios from "axios";
import moment from 'moment';

import useForm from "../utilities/useForm";
import { useTranslation } from "react-i18next";

const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";

function Profile() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [currentUser, setCurrentUser] = useState(null);
    const [fetchError, setFetchError] = useState("");
    const [companyName, setCompanyName] = useState("");

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            } else {
                axios.get(ENDPOINT, {
                    params: { id: userId },
                    withCredentials: true,
                })
                    .then((res) => {
                        let user = res.data.user;
                        setCurrentUser(user);
                        axios.get(process.env.REACT_APP_API_URL + "secure/company", {
                            params: { id: user.company_id },
                            withCredentials: true,
                        })
                            .then((res_cmp) => {
                                let company = res_cmp.data.company;
                                setCompanyName(company.name);
                            })
                    })
                    .catch((err) => {
                        if (err.response) {
                            setFetchError(err.response.data.message)
                            setTimeout(() => {
                                navigate("/login");
                            }, 1000);
                        }
                    })

            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    function callback(data) {
        const meta = {
            title: data["meta.title"],
            first_name: data["meta.first_name"],
            last_name: data["meta.last_name"],
            address: data["meta.address"],
            address2: data["meta.address2"],
            phone: data["meta.phone"],
            city: data["meta.city"],
            zip: data["meta.zip"],
            vat: data["meta.vat"],
        };
        let newData = {
            id: currentUser.id,
            email: currentUser.email,
            parent_id: currentUser.parent_id,
            role_id: currentUser.role_id,
            meta: meta,
        };
        if (data.password.length > 0) {
            newData['password'] = data.password;
        }

        return axios.put(ENDPOINT, newData, { withCredentials: true });
    }
    const additionalData = null;
    const { handleSubmit, status, message } = useForm({
        additionalData,
        callback,
    });

    return (
        <Container className="main-container">
            <Navbar></Navbar>
            <h3>Profile</h3>
            <Container className="p-4">
                <Row>
                    <Col>
                        {fetchError.length > 0 &&
                            <Alert key="danger" variant="danger">
                                <i>{fetchError}</i>
                            </Alert>
                        }
                        {currentUser !== null &&
                            <Card className="madevo-card">
                                <Card.Body>
                                    <Row>
                                        <Col className="d-flex justify-content-between mb-4 align-items-center">
                                            <strong className="madevo-text-secondary">Last Login: {moment(currentUser.last_login).format('MMMM Do YYYY, H:mm')}</strong>
                                            {status === 'success' &&
                                                <Badge bg="success">{message}</Badge>
                                            }
                                            {status === 'error' &&
                                                <Badge bg="danger">{message}</Badge>
                                            }
                                            <Badge className="madevo-badge">{companyName}</Badge>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form
                                                action={ENDPOINT}
                                                onSubmit={handleSubmit}
                                                method="POST"
                                                className="pb-2 mb-2"
                                            >
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridTitle">
                                                        <Form.Label>Title <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control required name="meta.title" type="text" placeholder="e.g. Manager" defaultValue={currentUser.meta.title} />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control required name="email" disabled type="email" placeholder="Enter email" defaultValue={currentUser.email} autoComplete="off" />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridPassword">
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control name="password" type="password" placeholder="Blank means unchanged" />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridFirstName">
                                                        <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control required name="meta.first_name" type="text" placeholder="Enter Name" defaultValue={currentUser.meta.first_name} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridLastName">
                                                        <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control required name="meta.last_name" type="text" placeholder="Enter Last Name" defaultValue={currentUser.meta.last_name} />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridPhone">
                                                        <Form.Label>Phone</Form.Label>
                                                        <Form.Control name="meta.phone" defaultValue={currentUser.meta.phone} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridAddress1">
                                                        <Form.Label>Address</Form.Label>
                                                        <Form.Control name="meta.address" defaultValue={currentUser.meta.address} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridAddress2">
                                                        <Form.Label>Address 2</Form.Label>
                                                        <Form.Control name="meta.address2" defaultValue={currentUser.meta.address2} />
                                                    </Form.Group>
                                                </Row>

                                                <Row className="mb-3">
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                        <Form.Label>City</Form.Label>
                                                        <Form.Control name="meta.city" defaultValue={currentUser.meta.city} />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridZip">
                                                        <Form.Label>Zip</Form.Label>
                                                        <Form.Control
                                                            name="meta.zip"
                                                            defaultValue={currentUser.meta.zip}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridVat">
                                                        <Form.Label>VAT</Form.Label>
                                                        <Form.Control
                                                            name="meta.vat"
                                                            defaultValue={currentUser.meta.vat}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                <div className="d-flex justify-content-end mt-4">
                                                    <Button variant="outline-secondary" className="madevo-btn-green" type="submit">
                                                        {t("Save")}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        }
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Container>
    );
}

export default Profile;

