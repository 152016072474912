import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useEffect, useState } from "react";
import { ArrowLeftCircle, CheckLg, SignDoNotEnter } from 'react-bootstrap-icons';

import axios from "axios";

import useForm from "../utilities/useForm";
import validator from "validator";
import { Link, useParams } from "react-router-dom";

const ENDPOINT = process.env.REACT_APP_API_URL + "user/reset-password";

function ResetPassword() {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [email, setEmail] = useState("");
    const [token, setToken] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);

    function passwordChanged(event) {
        const val = event.target.value;
        setPassword(val);
        setValidPassword(validator.isStrongPassword(val));
    }

    function confirmPasswordChanged(event) {
        const val = event.target.value;
        setConfirmPassword(val);
        setValidConfirmPassword(val === password);
    }

    const params = useParams();

    useEffect(() => {
        const token = params.token;
        const l_email = params.email;
        if (token.length === 0 || l_email === 0) {
            setError(true);
        } else {
            setEmail(l_email);
            setToken(token);
        }
    }, []);

    function callback(data) {
        const payload = {
            email: email,
            token: token,
            password: password,
        }
        setError(false);
        setSuccess(false);
        return axios.post(ENDPOINT, payload)
            .then((res) => {
                if (res.data.error) {
                    setError(true);
                } else {
                    setSuccess(true);
                }
            })
            .catch((e) => {
                if (e.response && e.response.data.error) {
                    setError(true);
                }
            });
    }
    const additionalData = null;
    const { handleSubmit } = useForm({
        additionalData,
        callback,
    });

    return (
        <Container className="App">
            <Container className="p-4">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card border="dark shadow">
                            <Card.Header>Reset Password</Card.Header>
                            <Card.Body>
                                {error === true &&
                                    <Alert key="danger" variant="danger">
                                        <i>Invalid parameters</i>
                                    </Alert>
                                }
                                {success === true &&
                                    <Alert key="success" variant="success">
                                        <i>Password updated successfully. You can go to <Link to={"/login"}>Login</Link> page.</i>
                                    </Alert>
                                }
                                <Row>
                                    <Col>
                                        <p className="text-primary">Please provide and confirm your new password.</p>
                                        <p className="text-info">Note that the link is valid only once.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form
                                            action={ENDPOINT}
                                            onSubmit={handleSubmit}
                                            method="POST"
                                            className="pb-2 mb-2"
                                        >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control name="email" type="email" placeholder="Enter email"
                                                        defaultValue={params.email} disabled
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    name="password"
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={passwordChanged}
                                                    className={password.length === 0 ? "" : validPassword ? "is-valid" : "is-invalid"}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formGroupConfirmPassword">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    name="confirm_password"
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    value={confirmPassword}
                                                    onChange={confirmPasswordChanged}
                                                    className={confirmPassword.length === 0 ? "" : validConfirmPassword ? "is-valid" : "is-invalid"}
                                                />
                                            </Form.Group>

                                            <div className="d-flex justify-content-between">
                                                <Link to={"/"} className="btn btn-outline-info">
                                                    <ArrowLeftCircle /> Home
                                                </Link>
                                                {(validPassword !== true || validConfirmPassword !== true) &&
                                                    <Button variant="danger" disabled type="button" className="fw-bolder">
                                                        <SignDoNotEnter />
                                                    </Button>
                                                }
                                                {validPassword === true && validConfirmPassword === true &&
                                                    <Button variant="primary" type="submit" className="fw-bolder">
                                                        <CheckLg /> Update
                                                    </Button>
                                                }
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ResetPassword;