import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { PencilSquare, PlusCircle, Trash3, Check } from 'react-bootstrap-icons';

import { useContext, useEffect, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import AccountEdit from './account_edit';

import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

function AccountList() {
    const { userRole, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [user_id, setUserId] = useState("");

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showRestore, setShowRestore] = useState(false);
    const [toDelete, setToDelete] = useState("");
    const [toRestore, setToRestore] = useState("");

    const [showActive, setShowActive] = useState(true);

    const { t } = useTranslation();

    function classDisabled(name) {
        return ['admin', 'dummy'].includes(name.toLowerCase()) ? ' disabled ' : '';
    }

    function handleEditButton(id) {
        setUserId(id)
        setShowEdit(true);
    }

    function handleNewButton(id) {
        setUserId("")
        setShowEdit(true);
    }

    function loadListData(userIsAdmin) {
        setIsLoading(true);
        let LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user/list";
        if (userIsAdmin) {
            LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user/hierarchy";
        }
        axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setAccounts(res.data.users);
            }
            setIsLoading(false);
        });
    }

    function loadCompanies() {
        let LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/companies";
        axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCompanies(res.data.companies);
            }
        });
    }

    function handleDeleteButton(id) {
        const usr = accounts.filter((u) => u._id === id);
        let name = undefined;
        if (usr.length > 0) {
            name = usr[0].meta.first_name;
        }
        if (name && classDisabled(name) === ' disabled ') return;
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData(userRole.access.manage_child_users);
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }

    function handleRestoreButton(id) {
        const usr = accounts.filter((u) => u._id === id);
        let name = undefined;
        if (usr.length > 0) {
            name = usr[0].meta.first_name;
        }
        if (name && classDisabled(name) === ' disabled ') return;
        setToRestore(id);
        setShowRestore(true);
    }
    function handleRestoreOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
        axios.patch(ENDPOINT,
            { id: toRestore },
            { withCredentials: true, }
        ).then((res) => {
            setToRestore("");
            setShowRestore(false)
            loadListData(userRole.access.manage_child_users);
        });
    }
    function handleRestoreCancel() {
        setToRestore("");
        setShowRestore(false);
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const access = res.data.user.role.access;
                        const userIsAdmin = access.manage_all_users || access.manage_child_users;
                        loadListData(userIsAdmin);
                        loadCompanies();
                    } else {
                        navigate("/login");
                    }
                })
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts
    return (
        <>
            <div className='d-flex justify-content-between my-2'>
                <Form.Group className="">
                    <Form.Check className="switch-success" id="isActive" name="is_active" type="switch" label={t("Is Active")} defaultChecked={showActive} onChange={() => setShowActive(!showActive)} />
                </Form.Group>
                {isLoading &&
                    <Spinner animation="border" role="status" size='sm' variant='secondary'>
                        <span className="visually-hidden">{t("Loading")}...</span>
                    </Spinner>
                }
                <Button variant='outline-secondary'
                    className='btn-sm madevo-btn-green'
                    onClick={handleNewButton}
                >
                    <PlusCircle /> {t("New Account")}
                </Button>
            </div>
            {!isLoading && accounts.length > 0 &&
                accounts.filter((item) => item.users && item.users.filter((item) => item.is_active === showActive).length > 0).map((item) => {
                    return (
                        <ListGroup className='my-2'>
                            <ListGroup.Item key={item._id}>
                                <div className="d-flex w-100 justify-content-center">
                                    {!showActive && item.is_active === showActive &&
                                        <h5 className="mt-1 text-secondary"><s>{item.name}</s></h5>
                                    }
                                    {(showActive || item.is_active !== showActive) &&
                                        <h5 className="mt-1">{item.name}</h5>
                                    }
                                </div>
                                <div className="mb-1">
                                    {item.users && item.users.filter((item) => item.is_active === showActive).length > 0 &&
                                        < Accordion className='my-4' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>{t("Users")}</Accordion.Header>
                                                <Accordion.Body>
                                                    <ListGroup>
                                                        {
                                                            item.users.filter((item) => item.is_active === showActive).map((user) => {
                                                                if (user.meta) {
                                                                    return (
                                                                        <ListGroup.Item key={user._id}>
                                                                            <div className="w-100 row">
                                                                                {!showActive && user.is_active === showActive &&
                                                                                    <h5 className="col mt-2 text-secondary"><s>{user.meta.first_name} {user.meta.last_name}</s></h5>
                                                                                }
                                                                                {(showActive || user.is_active !== showActive) &&
                                                                                    <h5 className="col mt-2">{user.meta.first_name} {user.meta.last_name}</h5>
                                                                                }
                                                                                <small className="col text-body-secondary mt-2"><Badge bg="secondary" className='mx-1'>{user.role.name}</Badge></small>
                                                                                <div className="col d-flex flex-column">
                                                                                    <small className="text-body-secondary d-none d-sm-block">{user.email}</small>
                                                                                    <small className="text-body-secondary d-none d-md-block"><strong>{t("Last login")}: {user.last_login ? moment(user.last_login).format('MMMM Do YYYY, H:mm') : ' - '}</strong></small>
                                                                                </div>
                                                                                <div className="col d-flex justify-content-end mt-2">
                                                                                    <PencilSquare role='button' className='list-action' onClick={() => handleEditButton(user._id)} title={t('Edit User')} />
                                                                                    {showActive &&
                                                                                        <Trash3 role='button' className={'list-action red-action '+classDisabled(user.meta.first_name)} onClick={() => handleDeleteButton(user._id)} title={t('Delete User')} />
                                                                                    }
                                                                                    {!showActive &&
                                                                                        <Check role='button' className={'list-action '+classDisabled(user.meta.first_name)} onClick={() => handleRestoreButton(user._id)} title={t('Restore User')} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </ListGroup.Item>
                                                                    );
                                                                }
                                                                return false;
                                                            })
                                                        }
                                                    </ListGroup>


                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    }
                                </div>

                            </ListGroup.Item>
                        </ListGroup >
                    )
                })
            }
            {showEdit &&
                <AccountEdit show={showEdit} handleShow={setShowEdit} user_id={user_id} setUserId={setUserId} reLoad={loadListData} accounts={accounts} companies={companies} />
            }
            {
                showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you Sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.user")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
            {
                showRestore &&
                <>
                    <Modal
                        show={showRestore}
                        onHide={() => setShowRestore(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you Sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.restore.user")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleRestoreCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleRestoreOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}

export default AccountList;
