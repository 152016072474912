import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';

import { useState } from "react";
import { ArrowLeft, CheckLg, SignDoNotEnter } from 'react-bootstrap-icons';

import axios from "axios";

import useForm from "../utilities/useForm";
import validator from "validator";
import { Link } from "react-router-dom";

const ENDPOINT = process.env.REACT_APP_API_URL + "user/get-reset-password-link";

function ForgotPassword() {
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);

    function onEmailChange(v) {
        const val = v.target.value;
        setEmail(val)
        setValidEmail(validator.isEmail(val));
        if (invalidEmail) {
            setInvalidEmail(false);
        }
    }

    function callback(data) {
        return axios.post(ENDPOINT, data)
            .then((res) => {
                if (res.data.error) {
                    setInvalidEmail(true);
                } else {
                    setEmailSent(true);
                }
            })
            .catch((e) => {
                if (e.response && e.response.data.error) {
                    setInvalidEmail(true);
                }
            });
    }
    const additionalData = null;
    const { handleSubmit, status, message } = useForm({
        additionalData,
        callback,
    });

    return (
        <Container className="App">
            <Container className="p-4">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <div className="w-100 text-center mb-4">
                            <Image src="logo.png" className="mx-auto w-2 pb-4" />

                            <h4 className="py-4 text-center">
                                Forgot Password?
                            </h4>

                            <p className="text-center">
                                Please provide your account email and we will send you a unique reset password link.
                            </p>
                        </div>
                        <div className="text-center">
                            {invalidEmail === true &&
                                <Alert key="danger" variant="danger">
                                    <i>Invalid email address</i>
                                </Alert>
                            }
                            {emailSent === true &&
                                <Alert key="success" variant="success">
                                    <i>Reset link emailed successfully</i>
                                </Alert>
                            }

                            <Form
                                action={ENDPOINT}
                                onSubmit={handleSubmit}
                                method="POST"
                                className="pb-2 mb-2"
                            >
                                <Row className="mb-4 w-75 mx-auto">
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <FloatingLabel label="E-mail address">
                                            <Form.Control name="email" type="email" placeholder="Enter email"
                                                value={email} onChange={onEmailChange}
                                                className={email.length === 0 ? "" : validEmail ? "is-valid" : "is-invalid"}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Row>

                                <div className="d-flex justify-content-center my-4">
                                    {validEmail !== true &&
                                        <Button variant="outline-secondary" disabled type="button" className="fw-bolder">
                                            Continue
                                        </Button>
                                    }
                                    {validEmail === true &&
                                        <Button variant="outline-secondary" type="submit" className="fw-bolder">
                                            Continue
                                        </Button>
                                    }
                                </div>
                                <div>
                                    <Link to={"/login"} className="link-underline-opacity-0 link-secondary" role="button">
                                        <ArrowLeft className="mx-2"/> Back
                                    </Link>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ForgotPassword;