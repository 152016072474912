import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { useRouteError } from "react-router-dom";

function Error() {
  const error = useRouteError();
  console.error(error);

  return (
    <Container id="error-page" className="mt-4">
      <Row>
        <Col md={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <Alert key="danger" variant="danger">
            <i>{error.statusText || error.message}</i>
          </Alert>
          <Link
            to={'..'}
            className="btn btn-outline-info"
          ><ArrowLeftCircle /> Back</Link>
        </Col>
      </Row>
    </Container>
  );
}

export default Error;
