import React, { useState } from 'react';
import { FileEarmarkArrowUp } from 'react-bootstrap-icons';

const DragDropUploader = (props) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length > 0) {
            props.startUpload(files[0])
        } else {
            console.warn('Could not handle file. Try with click.');
        }
    };

    return (
        <div
            role='button'
            onClick={(e) => { e.preventDefault(); e.stopPropagation(); props.picker.current.click() }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={"file-input w-100 d-flex flex-column justify-content-center align-items-center p-2 my-2 mx-auto" + (isDragging ? " file-input-over" : "")}
        >
            <span
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <FileEarmarkArrowUp />
            </span>
            <span className='text-small'
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                Click or Drop Files here
            </span>
            <span className='text-small'
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                .csv or .zip
            </span>
        </div>
    );
};

export default DragDropUploader;