import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

function SubscriptionEdit(props) {
    const { checkLoggedIn } = useContext(AuthContext);

    const [saving, setSaving] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [currentSubscription, setCurrentSubscription] = useState({ meta: {} });
    const [isNew, setIsNew] = useState(false);
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [companies, setCompanies] = useState([]);
    const [plans, setPlans] = useState([]);

    const { t } = useTranslation();

    function loadCompanies() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/companies";
        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCompanies(res.data.companies);
            }
        });
    }

    function loadPlans() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/plans";

        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setPlans(res.data.plans);
            }
        });
    }

    function loadSubscriptionData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "admin/subscription";
        return axios.get(LIST_ENDPOINT, {
            params: { id: props.subscription_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCurrentSubscription(res.data.subscription);
                return res.data.subscription;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        saveSubscription()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn().then(() => {
                    props.reLoad();
                })
            })
            .catch((e) => {
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setSubscriptionId("");
        }, 500);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newSubscription() {
        return {
            plan_id: "",
            company_id: "",
            meta: {},
        }
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.value;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        let tmpField = Object.assign({}, currentSubscription);
        if (subfield === undefined) {
            tmpField[field] = val;
        } else {
            tmpField[field][subfield] = val;
        }
        setCurrentSubscription(tmpField);
    }

    function handleChangeSwitch(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.checked;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        if (subfield === undefined) {
            currentUser[field] = val >= 1 ? 1 : 0;
        } else {
            currentUser[field][subfield] = val >= 1 ? 1 : 0;
        }
    }

    function saveSubscription() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/subscription";
        if (isNew) {
            return axios.post(ENDPOINT, currentSubscription, {
                withCredentials: true,
            });
        }
        return axios.put(ENDPOINT, currentSubscription, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (props.subscription_id !== undefined && props.subscription_id.length > 0) {
                    setIsNew(false);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: props.user_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            axios.get(ENDPOINT, {
                                params: { id: my_uid },
                                withCredentials: true,
                            })
                                .then((l_res) => {
                                    const canManageSubscriptions = l_res.data.user.role.access.manage_all_subscriptions || l_res.data.user.role.access.manage_self_subscriptions;
                                    if (canManageSubscriptions) {
                                        loadCompanies();
                                        loadPlans();
                                        loadSubscriptionData()
                                        .then(()=> setIsLoading(false));
                                    }
                                })
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setIsNew(true);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageUsers = l_res.data.user.role.access.manage_self_subscriptions || l_res.data.user.role.access.manage_all_subscriptions;
                            if (canManageUsers) {
                                loadCompanies();
                                loadPlans();
                                setCurrentSubscription(newSubscription());
                            }
                        }).finally(() => setIsLoading(false))
                }
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.subscription")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.subscription")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew &&
                        <Modal.Title>{t("New Subscription")}</Modal.Title>
                    }
                    {!isLoading && !isNew &&
                        <Modal.Title>{t("Edit Subscription")}</Modal.Title>
                    }
                    {isLoading &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage.length > 0 &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="danger">{errorMessage}</Badge>
                            </Col>
                        </Row>

                    }
                    {!isLoading && currentSubscription &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                {currentSubscription.created_at && !isNew &&
                                <strong className='text-small'>{t("Created At")}: {currentSubscription.created_at && !isNew ? moment(currentSubscription.created_at).format('MMMM Do YYYY, H:mm') : ' - '}</strong>
                                }
                            </Col>
                        </Row>
                    }
                    {isLoading &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='secondary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    <Row>
                        <Col>
                            {!isLoading && currentSubscription && // TODO: Convert to dropdowns with Companies and Plans
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridCompany">
                                            <Form.Label>{t("Company")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="company_id" value={currentSubscription.company_id} onChange={handleChangeField} >
                                                <option value={""}>{t("None")}</option>
                                                {
                                                    companies.map((company) => {
                                                        return (<option value={company._id} key={company._id} >
                                                            {company.name}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridPlan">
                                            <Form.Label>{t("Plan")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Select name="plan_id" value={currentSubscription.plan_id} onChange={handleChangeField} >
                                                <option value={""}>{t("None")}</option>
                                                {
                                                    plans.map((plan) => {
                                                        return (<option value={plan._id} key={plan._id} >
                                                            {plan.name}
                                                        </option>);
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>

                                </Form>
                            }
                            {!currentSubscription &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='madevo-btn-green' onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SubscriptionEdit;

