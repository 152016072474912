import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { useEffect, useState } from "react";

import axios from "axios";

import { useParams } from "react-router-dom";

const ENDPOINT = process.env.REACT_APP_API_URL + "user/activate-account";

function ActivateAccount() {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    useEffect(() => {
        const token = params.token;
        const l_email = params.email;
        if (token.length === 0 || l_email === 0) {
            setError(true);
        } else {
            axios.post(ENDPOINT, {
                "email": l_email,
                "token": token,
            }).then((res) => {
                if (res.data.error) {
                    setError(true);
                    setSuccess(false);
                } else {
                    setError(false);
                    setSuccess(true);
                }
            }).catch((e) => {
                setError(true);
                setSuccess(false);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, []);

    return (
        <Container className="App">
            <Container className="p-4">
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <Card border="dark shadow">
                            <Card.Header>Account Activation</Card.Header>
                            <Card.Body>
                                {error === true &&
                                    <Alert key="danger" variant="danger">
                                        <i>Invalid parameters</i>
                                    </Alert>
                                }
                                {success === true &&
                                    <Alert key="success" variant="success">
                                        <i>Account activated! You may close this window.</i>
                                    </Alert>
                                }
                                {loading &&
                                    <Row>
                                        <Col sm={1} className="text-center">
                                            <Spinner animation="border" role="status" size='sm' className="text-primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </Col>
                                        <Col>
                                            <p className="text-primary">Please wait while we are trying to activate your account.</p>
                                        </Col>
                                    </Row>
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default ActivateAccount;
