import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useContext, useState } from "react";
import validator from "validator";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';

import { ArrowLeft, CheckLg, SignDoNotEnter } from 'react-bootstrap-icons';

import useForm from "../utilities/useForm";
import AuthContext from "../helpers/AuthContext";

import Footer from "./footer";

const FORM_ENDPOINT = process.env.REACT_APP_API_URL + "user/login";

function Register() {
    const navigate = useNavigate();
    const { register, checkLoggedIn } = useContext(AuthContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);

    const [hasSubmitted, setHasSubmitted] = useState(false);

    function emailChanged(event) {
        const val = event.target.value;
        setEmail(val);
        setValidEmail(validator.isEmail(val));
    }

    function passwordChanged(event) {
        const val = event.target.value;
        setPassword(val);
        setValidPassword(validator.isStrongPassword(val));
    }

    function confirmPasswordChanged(event) {
        const val = event.target.value;
        setConfirmPassword(val);
        setValidConfirmPassword(val === password);
    }

    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (res) {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    const additionalData = {};

    function callback(data) {
        return register(data.email, data.password).then((res) => {
            if (!res.error) {
                setHasSubmitted(true);
            }
            return res;
        });
    }

    const { handleSubmit, status, message } = useForm({
        additionalData,
        callback,
    });

    return (
        <Container className="pt-4">
            <Row className="mt-4">
                <Col xl={{ span: 4, offset: 4 }}>
                    <div className="p-4">
                        <div className="w-100 text-center mb-4">
                          <Image src="logo.png" className="mx-auto w-2 pb-4" />

                          <div className="pt-4 pb-1">
                            <strong>Please fill in the registration form</strong>
                          </div>
                        </div>
                        {hasSubmitted === false &&
                            <Form
                                action={FORM_ENDPOINT}
                                onSubmit={handleSubmit}
                                method="POST"
                                className="pb-2 mb-2"
                            >
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <FloatingLabel label="E-mail address">
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={emailChanged}
                                        className={email.length === 0 ? "" : validEmail ? "is-valid" : "is-invalid"}
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupPassword">
                                    <FloatingLabel label="Password">
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={passwordChanged}
                                        className={password.length === 0 ? "" : validPassword ? "is-valid" : "is-invalid"}
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupConfirmPassword">
                                    <FloatingLabel label="Confirm Password">
                                    <Form.Control
                                        name="confirm_password"
                                        type="password"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={confirmPasswordChanged}
                                        className={confirmPassword.length === 0 ? "" : validConfirmPassword ? "is-valid" : "is-invalid"}
                                    />
                                    </FloatingLabel>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formGroupMessage">
                                    {status.length > 0 && (
                                        <Alert
                                            key={status === "success" ? "success" : "danger"}
                                            variant={status === "success" ? "success" : "danger"}
                                        >
                                            <i>{message}</i>
                                        </Alert>
                                    )}
                                </Form.Group>
                                <div className="d-flex justify-content-center my-4">
                                    {(validEmail !== true || validPassword !== true || validConfirmPassword !== true) &&
                                        <Button variant="outline-secondary" disabled type="button" className="fw-bolder">
                                            Continue
                                        </Button>
                                    }
                                    {validEmail === true && validPassword === true && validConfirmPassword === true &&
                                        <Button variant="outline-secondary" type="submit" className="fw-bolder">
                                            Continue
                                        </Button>
                                    }
                                </div>
                                <div className="d-flex justify-content-center">
                                    <Link to={"/login"} className="link-underline-opacity-0 link-secondary" role="button">
                                        <ArrowLeft className="mx-2"/> Back
                                    </Link>
                                </div>
                            </Form>
                        }
                        {hasSubmitted === true &&
                            <>
                                <Row>
                                    <Col>
                                        <Alert
                                            key={"success"}
                                            variant={"success"}
                                        >
                                            <i>{
                                                "Your account has been created but is not active yet. You have received an activation link in your email. " +
                                                "Please, contact your provider after activating the account."
                                            }</i>
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Link
                                            to={'..'}
                                            className="btn btn-outline-info"
                                        ><ArrowLeft className="mx-2" /> Back</Link>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </Col>
            </Row>
            <Footer />
        </Container >
    );
}

// TODO: Add Captcha

export default Register;