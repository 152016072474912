import Modal from 'react-bootstrap/Modal';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import AuthContext from '../../helpers/AuthContext';
import React, { useEffect, useContext, useState } from "react";


import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

function PlanEdit(props) {
    const { checkLoggedIn } = useContext(AuthContext);

    const [saving, setSaving] = useState(false)
    const [currentUser, setCurrentUser] = useState({ meta: {} });
    const [currentPlan, setCurrentPlan] = useState({ meta: {} });
    const [isNew, setIsNew] = useState(false);
    const [confirming, setConfirming] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const { t } = useTranslation();

    function loadPlanData() {
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "admin/plan";
        return axios.get(LIST_ENDPOINT, {
            params: { id: props.plan_id },
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setCurrentPlan(res.data.plan);
                return res.data.plan;
            }
            return {};
        })
            .catch((e) => {
                console.error(e.response.data.message);
            });
    }

    function handleCancel() {
        setConfirming(false);
        setSaving(false);
    }
    function handleOk() {
        setConfirming(false);
        setErrorMessage("");

        savePlan()
            .then((res) => {
                setSaving(false);
                handleClose();
                checkLoggedIn().then(() => {
                    props.reLoad();
                })
            })
            .catch((e) => {
                if (e.response.data.error) {
                    setErrorMessage("Error: " + e.response.data.message);
                }
            })

    }

    function handleClose() {
        props.handleShow(false);
        setTimeout(() => {
            props.setPlanId("");
        }, 500);
    }
    function handleSave() {
        for (const el of document.getElementById('edit-form').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                return;
            }
        }
        setSaving(false);
        setConfirming(true);
    }

    function newPlan() {
        return {
            name: "",
            is_default: 0,
            price: 10.0,
            currency: "GBP",
            frequency: "Monthly",
            features: {
                max_active_alerts_per_user: 1,
                max_datasources: 2,
                max_forecasting_data_points: 3,
                max_llm_queries_per_user: 10,
                max_total_file_size_mb: 1,
                max_users: 5,
            },
        }
    }

    function handleChangeField(e) {
        let field = e.target.name;
        let subfield = undefined;
        let val = e.target.value;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        if (e.target.type === "number") {
            val = parseFloat(val);
        }

        if (subfield === undefined) {
            currentPlan[field] = val;
        } else {
            currentPlan[field][subfield] = val;
        }
    }

    function handleChangeSwitch(e) {
        let field = e.target.name;
        let subfield = undefined;
        const val = e.target.checked;

        if (field.includes('meta')) {
            subfield = field.replace("meta.", "");
            field = 'meta';
        }

        let tmpPlan = Object.assign({}, currentPlan);
        if (subfield === undefined) {
            tmpPlan[field] = val >= 1 ? 1 : 0;
        } else {
            tmpPlan[field][subfield] = val >= 1 ? 1 : 0;
        }
        setCurrentPlan(tmpPlan);
    }

    function savePlan() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/plan";
        if (isNew) {
            return axios.post(ENDPOINT, currentPlan, {
                withCredentials: true,
            });
        }
        return axios.put(ENDPOINT, currentPlan, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        setIsLoading(true);
        checkLoggedIn().then((res) => {
            if (res) {
                const my_uid = res;
                if (props.plan_id !== undefined && props.plan_id.length > 0) {
                    setIsNew(false);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: props.user_id },
                        withCredentials: true,
                    })
                        .then((res) => {
                            let user = res.data.user;
                            setCurrentUser(user);
                            // FIXME: Plans
                            const canManagePlans = user.role.access.manage_child_users;
                            if (canManagePlans) {
                                loadPlanData();
                            }
                        })
                        .catch((err) => {
                            if (err.response) {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setIsNew(true);
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    axios.get(ENDPOINT, {
                        params: { id: my_uid },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            const canManageUsers = l_res.data.user.role.access.manage_plans;
                            if (canManageUsers) {
                                setCurrentPlan(newPlan());
                            }
                        })
                }
                setIsLoading(false);
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts


    return (
        <>
            <Modal
                show={confirming}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Are you sure?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isNew &&
                        <p>{t("confirm.new.plan")}</p>
                    }
                    {!isNew &&
                        <p>{t("confirm.modify.plan")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCancel}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="success" onClick={handleOk}>
                        {t("Yes")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    {!isLoading && isNew && currentPlan && currentPlan.features &&
                        <Modal.Title>{t("New Plan")}</Modal.Title>
                    }
                    {!isLoading && !isNew && currentPlan && currentPlan.features &&
                        <Modal.Title>{t("Edit Plan")}</Modal.Title>
                    }
                    {(isLoading || !currentPlan || !currentPlan.features) &&
                        <Modal.Title>{t("Loading")}...</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        errorMessage.length > 0 &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4">
                                <Badge bg="danger">{errorMessage}</Badge>
                            </Col>
                        </Row>

                    }
                    {(isLoading || !currentPlan || !currentPlan.features) &&
                        <div className="text-center">
                            <Spinner animation="border" role="status" size='sm' variant='secondary'>
                                <span className="visually-hidden">{t("Loading")}...</span>
                            </Spinner>
                        </div>
                    }
                    {currentPlan && currentPlan.features &&
                        <Row>
                            <Col className="d-flex justify-content-between mb-4 col-8">
                            {currentPlan.created_at && !isNew &&
                                <strong className='text-small'>{t("Created At")}: {currentPlan.created_at && !isNew ? moment(currentPlan.created_at).format('MMMM Do YYYY, H:mm') : ' - '}</strong>
                            }
                            </Col>
                            <Form.Group as={Col} className="col-4 d-flex justify-content-end pe-3">
                                <Form.Check className="switch-success" id="isDefault" name="is_default" type="switch" label={t("Is Default")} defaultChecked={currentPlan.is_default === 1} onChange={handleChangeSwitch} />
                            </Form.Group>
                        </Row>
                    }
                    <Row>
                        <Col>
                            {currentPlan && currentPlan.features &&
                                <Form
                                    id='edit-form'
                                    className="pb-2 mb-2"
                                >
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridName">
                                            <Form.Label>{t("Name")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="name" type="text" defaultValue={currentPlan.name} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPrice">
                                            <Form.Label>{t("Price")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="price" type="number" min="0" step="0.01" defaultValue={currentPlan.price} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridCurrency">
                                            <Form.Label>{t("Currency")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="currency" type="text" defaultValue={currentPlan.currency} onChange={handleChangeField} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridFrequency">
                                            <Form.Label>{t("Frequency")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="frequency" type="text" defaultValue={currentPlan.frequency} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridUsers">
                                            <Form.Label>{t("Max Users")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="features.max_users" type="number" min="0" defaultValue={currentPlan.features.max_users} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridDS">
                                            <Form.Label>{t("Max Datasources")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control required name="features.max_datasources" type="number" min="0" defaultValue={currentPlan.features.max_datasources} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridFileSize">
                                            <Form.Label>{t("Max Total File Size")} (MB) <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="features.max_total_file_size_mb" type="number" min="0" defaultValue={currentPlan.features.max_total_file_size_mb} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridQueries">
                                            <Form.Label>{t("Max Chatbot Queries per User per Month")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="features.max_llm_queries_per_user" type="number" min="0" defaultValue={currentPlan.features.max_llm_queries_per_user} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridForecast">
                                            <Form.Label>{t("Max Forecasting Data Points per User per Month")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="features.max_forecasting_data_points" type="number" min="0" defaultValue={currentPlan.features.max_forecasting_data_points} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridAlerts">
                                            <Form.Label>{t("Max Active Alerts per User")} <span className="text-danger">*</span></Form.Label>
                                            <Form.Control name="features.max_active_alerts_per_user" type="number" min="0" defaultValue={currentPlan.features.max_active_alerts_per_user} onChange={handleChangeField} />
                                        </Form.Group>
                                    </Row>

                                </Form>
                            }
                            {!currentPlan &&
                                <Spinner animation="border" role="status" size='sm'>
                                    <span className="visually-hidden">{t("Loading")}...</span>
                                </Spinner>
                            }
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" className='madevo-btn-green' onClick={handleSave}>
                        {saving &&
                            <Spinner animation="border" role="status" size='sm'>
                                <span className="visually-hidden">{t("Saving")}...</span>
                            </Spinner>
                        }
                        {!saving &&
                            <span>{t("Save")}</span>
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PlanEdit;