import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import React, { useEffect, useContext, useState } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import { Outlet, useNavigate } from 'react-router-dom';
import AuthContext from '../../helpers/AuthContext';

import { useTranslation } from 'react-i18next';

import axios from "axios";

function Alerts() {

    const { userRole, checkLoggedIn } = useContext(AuthContext);
    const [activeAlert, setActiveAlert] = useState("");

    const { t } = useTranslation();
    const navigate = useNavigate();

    function runAlert(id) {
        const ENDPOINT = process.env.REACT_APP_API_URL + "secure/alert/run";
        axios.post(ENDPOINT, { id: id }, {
            withCredentials: true,
        });
    }

    useEffect(() => {
        if (userRole === undefined || userRole.access === undefined) {
            checkLoggedIn().then((res) => {if (!res) navigate("/login");});
        }
    })

    return (
        <Container className="main-container">
            <Navbar></Navbar>
            <h3>{t("Alert Management")}</h3>
            <Container className="p-0 p-md-4 container-fluid mb-5">
                <Row>
                    <Col>
                        <Card className="madevo-card">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Outlet context={[activeAlert, setActiveAlert, runAlert]} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Container>
    );
}

export default Alerts;
