/* eslint-disable react-hooks/exhaustive-deps */
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';

import { PencilSquare, PlusCircle, Trash3 } from 'react-bootstrap-icons';

import { useContext, useEffect, useState } from "react";
import AuthContext from "../../helpers/AuthContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import PlanEdit from "./edit";
import Spinner from 'react-bootstrap/Spinner';

function PlanList() {
    const { userId, checkLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const [activePlan, setActivePlan] = useOutletContext();
    const [planId, setPlanId] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [plans, setPlans] = useState([]);

    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [toDelete, setToDelete] = useState("");

    const { t } = useTranslation();

    function handleNewButton(id) {
        setActivePlan("new");
        setPlanId("")
        setShowEdit(true);
    }

    function loadListData() {
        setIsLoading(true);
        const LIST_ENDPOINT = process.env.REACT_APP_API_URL + "secure/plans";

        return axios.get(LIST_ENDPOINT, {
            withCredentials: true,
        }).then((res) => {
            if (res && res.data) {
                setPlans(res.data.plans);
            }
            setIsLoading(false);
        });
    }

    function handleEditButton(id) {
        setPlanId(id)
        setShowEdit(true);
    }

    function handleDeleteButton(id) {
        setToDelete(id);
        setShowDelete(true);
    }
    function handleDeleteOk() {
        const ENDPOINT = process.env.REACT_APP_API_URL + "admin/plan";
        axios.delete(ENDPOINT, {
            data: { id: toDelete },
            withCredentials: true,
        }).then((res) => {
            setToDelete("");
            setShowDelete(false)
            loadListData()
            if (activePlan === toDelete) {
                setActivePlan("");
            }
        });
    }
    function handleDeleteCancel() {
        setToDelete("");
        setShowDelete(false);
    }
    useEffect(() => {
        checkLoggedIn().then((res) => {
            if (!res) {
                navigate("/login");
            }
            if (res) {
                const INFO_ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                axios.get(INFO_ENDPOINT, {
                    params: { id: res },
                    withCredentials: true,
                }).then((res) => {
                    if (res && res.data) {
                        const canManagePlans = res.data.user.role.access.manage_plans;
                        if (!canManagePlans) {
                            navigate("/");
                        } else {
                            loadListData();
                        }
                    } else {
                        navigate("/login");
                    }
                })
                    .catch((e) => {
                        console.error(e);
                    })
            } else {
                navigate("/");
            }
        });
    }, []); // Empty dependency array means this effect runs once when the component mounts

    return (
        <>
            <div className='d-flex justify-content-between my-2'>
                <h4 className='madevo-text-secondary'>{t("Plans")}</h4>
                {isLoading &&
                    <Spinner animation="border" role="status" size='sm' variant='secondary'>
                        <span className="visually-hidden">{t("Loading")}...</span>
                    </Spinner>
                }
                <Button variant='outline-secondary' className='btn-sm new-btn madevo-btn-green my-1' onClick={handleNewButton}>
                    <PlusCircle className='new-btn-plus' /> {t("New Plan")}
                </Button>
            </div>
            {
                plans.map((item) => {
                    return (
                        <ListGroup className='my-2' key={item._id}>
                            <ListGroup.Item>
                                <div className="row">
                                    <div className="col d-flex flex-column justify-content-center align-items-start">
                                        <div className=''>
                                            <h5 className="mb-0">{item.name}</h5>
                                            <small className="text-body-secondary">
                                                <Badge bg="primary" className="mx-1">
                                                    {item.price} {item.currency} / {item.frequency}
                                                </Badge>
                                                {item.is_default === 1 &&
                                                    <Badge bg="secondary" className="mx-1">
                                                        default
                                                    </Badge>
                                                }
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col d-flex justify-content-center align-items-center">
                                        <small>
                                            <Badge bg="secondary" className='mx-1'>
                                                {t("Created At")}: {moment(item.created_at).format('MMMM Do YYYY, H:mm')}
                                            </Badge>
                                        </small>
                                    </div>
                                    <div className="col d-flex justify-content-end align-items-center">
                                        <PencilSquare role='button' className='list-action' onClick={() => handleEditButton(item._id)} title={t("Edit")} />
                                        <Trash3 role='button' className='list-action red-action' onClick={() => handleDeleteButton(item._id)} title={t("Delete")} />
                                    </div>
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    )
                }
                )
            }
            {showEdit &&
                <PlanEdit show={showEdit} handleShow={setShowEdit} user_id={userId} plan_id={planId} setPlanId={setPlanId} reLoad={loadListData} plans={plans} />
            }
            {showDelete &&
                <>
                    <Modal
                        show={showDelete}
                        onHide={() => setShowDelete(false)}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("Are you sure?")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{t("confirm.delete.plan")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleDeleteCancel}>
                                {t("Cancel")}
                            </Button>
                            <Button variant="success" onClick={handleDeleteOk}>
                                {t("Yes")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    );
}

export default PlanList;