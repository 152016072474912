import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Dashboard() {
  return (
    <Container className="App">
      <Row>
        <Col>
          <header className="App-header">Madevo AI & IoT App v1.0.0-alpha</header>
          <h3>Dashboard</h3>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
