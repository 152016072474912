import React, { useState } from 'react';
import AuthContext from './AuthContext';
import axios from "axios";
import Cookies from "js-cookie";

const API_URL = process.env.REACT_APP_API_URL;

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userId, setUserId] = useState("");
    const [userRole, setUserRole] = useState({});

    const login = async (email, password) => {
        const response = await axios
            .post(
                API_URL + "user/login",
                {
                    email,
                    password,
                },
                { withCredentials: true }
            );
        const token = Cookies.get("token");
        if (token) {
            localStorage.setItem("madevo-app-token", token);
        }
        const refresh_token = Cookies.get();
        if (refresh_token) {
            localStorage.setItem("madevo-app-refresh-token", refresh_token);
        }
        setLoggedIn(true);
        setUserId(response.data.user.id);
        setUserRole(response.data.user.role);
        return response.data;
    };

    const register = async (email, password) => {
        const response = await axios
            .post(
                API_URL + "user/register",
                {
                    email: email,
                    password: password,
                    is_dummy: false,
                },
            );
        return response.data;
    };

    const logout = async () => {
        try {
            const response = await axios
                .post(
                    API_URL + "auth/logout",
                    {},
                    { withCredentials: true }
                );
            localStorage.removeItem("madevo-app-user");
            setLoggedIn(false);
            window.location = "/";
            return response;
        } catch (e) {
            const res = e.response.data;
            if (res.error) {
                setLoggedIn(false);
                setUserId("");
                window.location = "/";
            }
        }
    };

    const checkLoggedIn = async () => {
        try {
            return axios
                .get(API_URL + "user/is-logged-in", { withCredentials: true })
                .then((response) => {
                    let ret = "";
                    const isAuthenticated = !response.data.error;
                    if (isAuthenticated) {
                        const uid = response.data.id;
                        setUserId(uid);
                        setLoggedIn(true);
                        ret = uid;
                    } else {
                        axios.post(API_URL + "user/is-logged-in", {}, { withCredentials: true })
                            .then((res) => {
                                if (res.error) {
                                    logout();
                                } else {
                                    ret = userId;
                                }
                            });
                        logout();
                    }
                    return ret;
                })
                .then((res) => {
                    const ENDPOINT = process.env.REACT_APP_API_URL + "secure/user";
                    return axios.get(ENDPOINT, {
                        params: { id: res },
                        withCredentials: true,
                    })
                        .then((l_res) => {
                            setUserRole(l_res.data.user.role);
                            return res;
                        })
                })
                .catch((e) => {
                    if (!e.response) return false;
                    const res = e.response.data;
                    if (res.error) {
                        setUserId("");
                        setLoggedIn(false);
                        setUserRole({});
                        return false;
                    }
                });
        } catch (error) {
            console.error("Error checking login status: ", error);
            setUserId("");
            setLoggedIn(false);
            return false;
        }

    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, userId, userRole, login, register, logout, checkLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
