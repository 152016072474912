import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useContext } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';

import { CheckLg } from 'react-bootstrap-icons';

import useForm from "../utilities/useForm";
import AuthContext from "../helpers/AuthContext";

import Footer from "./footer";

const FORM_ENDPOINT = process.env.REACT_APP_API_URL + "user/login";

function Login() {
  const navigate = useNavigate();
  const { login, checkLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    checkLoggedIn().then((res) => {
      if (res) {
        navigate("/");
      }
    });
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const additionalData = {
    sent: new Date().toISOString(),
  };

  function callback(data) {
    return login(data.email, data.password).then((res) => {
      if (!res.error) {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
      return res;
    });
  }

  const { handleSubmit, status, message } = useForm({
    additionalData,
    callback,
  });

  return (
    <Container className="pt-4">
      <Row className="mt-4">
        <Col xl={{ span: 4, offset: 4 }}>
          <div className="p-4 border-secondary">
            <div className="w-100 text-center mb-4">
              <Image src="logo.png" className="mx-auto w-2 pb-4" />

              <h3 className="py-4">
                Welcome to Madevo
              </h3>
            </div>
            <Form
              action={FORM_ENDPOINT}
              onSubmit={handleSubmit}
              method="POST"
              className="pb-2 mb-2"
            >
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <FloatingLabel label="E-mail address">
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter email"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupPassword">
                <FloatingLabel label="Password">
                  <Form.Control
                    name="password"
                    type="password"
                    placeholder="Password"
                  />
                </FloatingLabel>
              </Form.Group>
              <Form.Group className="mb-4" controlId="formGroupMessage">
                {status.length > 0 && status !== 'loading' && (
                  <Alert
                    key={status === "success" ? "success" : "danger"}
                    variant={status === "success" ? "success" : "danger"}
                  >
                    <i>{message}</i>
                  </Alert>
                )}
              </Form.Group>
              <div className="d-grid">
                <Button variant="secondary" type="submit" className="fw-bolder my-4">
                  Login
                </Button>
              </div>
            </Form>
            <Row className="my-4">
              <Col className="text-center">
                <Link className="link-secondary" to="/forgot-password">Forgot Password?</Link>
              </Col>
            </Row>
            <Row>
              <Col className="text-secondary text-center">
                Don't have an account? <Link className="link-danger" to="/register">Register</Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Footer />
    </Container >
  );
}

// TODO: Add Captcha

export default Login;
