import Container from "react-bootstrap/Container";
import Card from 'react-bootstrap/Card';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import AuthContext from "../helpers/AuthContext";
import React, { useEffect, useContext, useState } from "react";

import Navbar from "../navbar";
import Footer from "../footer";
import { Link, Outlet, useLocation } from 'react-router-dom';
import AuthContext from '../../helpers/AuthContext';

import { useTranslation } from 'react-i18next';

function Companies() {

    const { userRole, checkLoggedIn } = useContext(AuthContext);
    const location = useLocation();
    const [activeCompany, setActiveCompany] = useState("");

    const { t } = useTranslation();

    function isActive(name) {
        return location.pathname.includes(name) ? " active " : "";
    }

    function detailsDisabled() {
        return activeCompany === "" ? ' disabled ' : '';
    }

    useEffect(() => {
        if (userRole === undefined || userRole.access === undefined) {
            checkLoggedIn();
        }
    })

    return (
        <Container className="main-container">
            <Navbar></Navbar>
            <h3>{t("Company Management")}</h3>
            <Container className="p-0 p-md-4 container-fluid mb-5">
                <Row>
                    <Col>
                        <Card className="madevo-card">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <Outlet context={[activeCompany, setActiveCompany]} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </Container>
    );
}

export default Companies;